import React, { useCallback, useEffect, useState } from "react";
import { useWs } from "./WebSocketContext";
import { MessageType } from "./websocketConstants";
import hourglass from "../Animations/hourglass.json";
import Lottie from "lottie-react";
import { useAuth } from "../contexts/AuthContext";

function sumArrayElements(arr) {
  // Using the reduce function to sum the array elements
  // The initialValue (0 in this case) is the starting value for the sum
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
}

export default function GameOver() {
  const { currentUser, getUserProfile } = useAuth();
  const [timeLeft, setTimeLeft] = useState(120);
  const { ws } = useWs();
  const [leaderboardData, setLeaderboardData] = useState([]);

  const handleGameOver = useCallback((leaderboard) => {
    console.log(leaderboard);
    setLeaderboardData(leaderboard);
  }, []);

  useEffect(() => {
    if (ws === null) return;
    const handleMessage = (event) => {
      try {
        const data = event.data;
        const messages = data.split("\n").map((message) => {
          return JSON.parse(message);
        });
        messages.forEach((message) => {
          if (message.type === MessageType.STATS_UPDATE) {
            setTimeLeft(Math.max(120 - message.message.ticks, 0));
          } else if (message.type === MessageType.GAME_OVER) {
            handleGameOver(message.message);
            getUserProfile(currentUser.uid);
          }
        });
      } catch (e) {
        console.log("error " + event.data);
      }
    };
    ws.addEventListener("message", handleMessage);

    return () => ws.removeEventListener("message", handleMessage);
  }, [ws, handleGameOver]);

  const getOrdinalSuffix = (number) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = number % 10;
    const teens = (number % 100) - remainder;

    return suffixes[remainder - 1] || (teens === 10 ? "th" : "th");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <>
        {leaderboardData.length > 0 ? (
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Spots</th>
                <th>Time</th>
                <th>Prize</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((entry, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td>
                    {index + 1}
                    {getOrdinalSuffix(index + 1)}
                  </td>
                  <td>{entry.NickName}</td>
                  <td>{sumArrayElements(entry.Spots)}</td>
                  <td>{entry.Time.toFixed(2)}</td>
                  <td>${entry.Prize}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <span>
            <h1> Please wait </h1>
            <p style={{ fontSize: "1em" }}>
              The leaderboard will appear in {timeLeft} seconds.
            </p>
            <Lottie animationData={hourglass} style={style} />
          </span>
        )}
      </>
    </div>
  );
}

const style = {
  height: 300,
  width: 150,
  margin: "auto",
};
