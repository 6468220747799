import React, { useState, useEffect } from "react";
import { Alert, Table } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import "../style/Bills.css";
import Popup from "../Components/Popup";
import axios from "axios";

const getButtonText = (status) => {
  switch (status) {
    case "uploaded":
      return "Unpaid";
    case "inUse":
      return "In use";
    case "unpaid":
      return "Get Paid";
    case "processing":
      return "Processing";
    case "paid":
      return "Paid";
    default:
      return "";
  }
};

const BillsPage = () => {
  const { currentUser, getUserProfile } = useAuth();
  const [notification, setNotification] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPulsing, setIsPulsing] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [isUploadIconVisible, setIsUploadIconVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const uploadIcon = process.env.REACT_APP_CDN_IMAGES_PATH + "/uploading.png";
  const removeIcon = process.env.REACT_APP_CDN_IMAGES_PATH + "/removing.png";

  useEffect(() => {
    // Check if there are any bills with "uploading" status
    const hasUploadingBills =
      currentUser &&
      currentUser.profile &&
      currentUser.profile.Bills &&
      currentUser.profile.Bills.some((bill) => bill.Status === "uploaded");

    // Show the uploading icon only if there are no bills with "uploading" status
    setIsUploadIconVisible(!hasUploadingBills);
  }, [currentUser]);

  const handleBillClick = (bill) => {
    console.log(bill);
    if (bill.Status === "uploaded") {
      if (selectedBill === bill) {
        setSelectedBill(null);
      } else setSelectedBill(bill);
    }
  };

  const openPopup = () => {
    setIsOpen(true);
    setNotification(null);

    setIsPulsing(true);
    setTimeout(() => {
      setIsPulsing(false);
    }, 100);
  };

  const closePopup = () => {
    setIsOpen(false);

    getUserProfile(currentUser.uid);
  };

  const onSuccess = () => {
    getUserProfile(currentUser.uid);
    setNotification({
      type: "success",
      message: "Bill was uploaded successfully!",
    });
  };

  const onError = () => {
    setNotification({
      type: "danger",
      message: "File upload failed.",
    });
  };
  const handleRemoveBill = async () => {
    if (!selectedBill) return;

    setIsLoading(true);

    // Call an API to remove the bill using selectedBill.ID
    // Make the API call and handle success or failure
    try {
      const response = await fetch(
        `/api/bills/remove/${currentUser.uid}/${selectedBill.ID}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // Handle success

        setNotification({
          type: "success",
          message: "Bill was removed successfully!",
        });
        getUserProfile(currentUser.uid);
      } else {
        // Handle failure

        setNotification({
          type: "danger",
          message: "Failed to remove the bill.",
        });
      }
    } catch (error) {
      console.error("Error removing bill:", error);
      alert("Error removing the bill.");
    } finally {
      setIsLoading(false);
    }

    setSelectedBill(null);
  };

  const handleWithdraw = async (billId) => {
    if (currentUser?.profile?.Paypal === "") {
      setNotification({
        type: "danger",
        message: "Please update your Paypal account",
      });
      return;
    }
    try {
      await axios.get(`/api/bills/get-paid/${currentUser.uid}/${billId}`);
      // Reload user profile after withdrawal
      getUserProfile(currentUser.uid);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="default-container">
      {currentUser && currentUser.profile && (
        <>
          <div className="bills-header">
            <h1>My Bills</h1>

            {isUploadIconVisible && (
              <img
                className={`action-icon ${isPulsing ? "pulse" : ""}`}
                onClick={openPopup}
                src={uploadIcon}
                alt="upload"
              />
            )}
            {selectedBill && (
              <img
                className={`action-icon ${isPulsing ? "pulse" : ""}`}
                src={removeIcon}
                alt="remove"
                onClick={handleRemoveBill}
              />
            )}
          </div>

          {notification && (
            <Alert className="text-center" variant={notification.type}>
              {notification.message}
            </Alert>
          )}
          <Table bordered>
            <thead>
              <tr>
                <th className="text-center"> Id</th>
                <th className="text-center">Amount</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentUser.profile.Bills?.map((bill, i) => (
                <tr
                  key={bill.ID}
                  className={
                    bill === selectedBill ? "selected-bill" : "default-bill"
                  }
                  onClick={() => handleBillClick(bill)}>
                  <td className="text-center align-middle">{i + 1}</td>
                  <td className="text-center align-middle">
                    {formatNumberWithCommas(bill.Amount)} {bill.Currency}
                  </td>

                  <td className="text-center align-middle">
                    {bill.Status === "unpaid" ? (
                      <button
                        className="bills-button"
                        onClick={() => {
                          if (bill.Status === "unpaid") {
                            handleWithdraw(bill.ID);
                          }
                        }}
                        disabled={bill.Status !== "unpaid"}>
                        {getButtonText(bill.Status)}
                      </button>
                    ) : (
                      <>{getButtonText(bill.Status)}</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Popup
            isOpen={isOpen}
            onClose={closePopup}
            onSuccess={onSuccess}
            onError={onError}
            currentUser={currentUser}
          />
        </>
      )}
    </div>
  );
};

function formatNumberWithCommas(input) {
  // Use parseFloat to ensure it's treated as a number (even if passed as a string)
  const number = parseFloat(input);

  // Check if it's a valid number
  if (isNaN(number)) {
    return "Invalid input";
  }

  // Use toLocaleString() to add commas for thousands separators
  return number.toLocaleString();
}

export default BillsPage;
