import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import "./Grid.css";

function Ticker({ handleTimeOver, random, isTimeRunning }) {
  const [time, setTime] = useState(10);
  const [timerExpired, setTimerExpired] = useState(false);

  useEffect(() => {
    if (!isTimeRunning) return;
    const intervalRef = setInterval(() => {
      setTime((prevtime) => Math.max(prevtime - 0.1, 0));
    }, 100);

    return () => {
      clearInterval(intervalRef);
    };
  }, [isTimeRunning]);

  useEffect(() => {
    setTime(10);
    setTimerExpired(false);
  }, [random]);

  useEffect(() => {
    if (time === 0 && !timerExpired) {
      console.log("time os zerroo");
      setTimerExpired(true);
      handleTimeOver();
    }
  }, [time, timerExpired, handleTimeOver]);

  return (
    <div>
      <p style={{ marginBottom: "0", textAlign: "center", fontSize: "15px" }}>
        TIME
      </p>
      <div className="ticker-box">{time.toFixed(1)}</div>
    </div>
  );
}

export default Ticker;
