import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Row } from "react-bootstrap";
import Grid from "../Components/Grid";
import PlayersCounter from "../Components/PlayersCounter";
import GameOver from "../Components/GameOver";
import PreGame from "../Components/PreGame";
import Ticker from "../Components/Ticker";
import { useWs } from "../Components/WebSocketContext";
import useFetchCompetition from "../Components/useFetchCompetition";
import { MessageType } from "../Components/websocketConstants";
import ProgressBar from "../Components/ProgressBar";
import useSoundPlayer from "../Components/useSoundPlayer";
import correct from "../Audio/correct-sound.mp3";
import wrong from "../Audio/wrong.mp3";

const GameState = {
  PRE_GAME: "preGame",
  GAME: "game",
  LOCKED: "locked",
  GAME_OVER: "gameover",
};

function GameArena() {
  const [gameState, setGameState] = useState(GameState.PRE_GAME);
  const [isTimeRunning, setIsTimeRunning] = useState(false);
  const [round, setRound] = useState(0);
  const [random, setRandom] = useState(0);
  const [selectedCell, setSelectedCell] = useState({ cell: null, type: 0 });
  const corrects = useRef([]);
  const results = useRef(null);
  const { ws } = useWs();
  const { playCorrect, playWrong } = useSoundPlayer(correct, wrong);

  const { isLoading, error, competition, urls, fetchCompetition } =
    useFetchCompetition();

  const handleClick = useCallback(
    (cell) => {
      if (gameState === GameState.LOCKED || cell === null) return;

      setGameState(GameState.LOCKED);

      const answer = cell[0] * 6 + cell[1];

      const message = {
        type: MessageType.USER_ANSWER,
        message: String(answer),
      };
      ws.send(JSON.stringify(message));
    },
    [ws, gameState]
  );

  const handleTimeOver = useCallback(() => {
    handleClick([-5, -5]);
  }, [handleClick]);

  const decode = (num) => {
    return (num + 333) / 10;
  };

  const handleFeedback = useCallback(
    (message) => {
      if (message.feedback === 1) {
        corrects.current.push(1);
        playCorrect();
      } else {
        corrects.current.push(0);
        playWrong();
      }
      if (round < 12) {
        setGameState(GameState.GAME);
        setRound((prevRound) => {
          return prevRound + 1;
        });
        setRandom(decode(message.spot));
      } else {
        setGameState(GameState.GAME_OVER);
      }
    },
    [playCorrect, playWrong, round]
  );

  useEffect(() => {
    if (ws === null) return;
    const handleMessage = (event) => {
      try {
        const data = event.data;
        const messages = data.split("\n").map((message) => {
          return JSON.parse(message);
        });
        messages.forEach((message) => {
          if (message.type === MessageType.USER_FEEDBACK) {
            handleFeedback(message.message);
          } else if (message.type === MessageType.GAME_START) {
            setIsTimeRunning(true);
            setGameState(GameState.GAME);
            setRound((prevRound) => {
              return prevRound + 1;
            });
            setRandom(decode(message.message));
          }
        });
      } catch (e) {
        console.log("error " + event.data);
      }
    };
    ws.addEventListener("message", handleMessage);

    return () => ws.removeEventListener("message", handleMessage);
  }, [ws, handleFeedback]);

  if (gameState === GameState.GAME_OVER) {
    return (
      <div className="default-container">
        <div className="mb-3 text-center">
          <GameOver results={results.current} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="default-container">
        <Alert variant="info text-center">Loading...</Alert>
      </div>
    );
  } else if (error) {
    return (
      <div className="default-container">
        <Alert variant="danger text-center">Something went wrong</Alert>
      </div>
    );
  } else if (!competition) {
    return (
      <div className="default-container">
        <Alert variant="info text-center">No upcoming rounds</Alert>
      </div>
    );
  }

  console.log("Round number is: " + round);

  return (
    <div className="default-container">
      {gameState === GameState.PRE_GAME ? (
        <PreGame
          competition={competition}
          fetchCompetition={fetchCompetition}
        />
      ) : (
        <>
          <div className="game-info">
            <Ticker
              random={random}
              handleTimeOver={handleTimeOver}
              isTimeRunning={isTimeRunning}
            />
            <ProgressBar corrects={corrects.current} />
            <PlayersCounter />
          </div>

          <Grid
            random={random}
            set={urls[round - 1]}
            selectedCell={selectedCell}
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
}

export default GameArena;
